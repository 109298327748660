<template>
    <div>
        <a-modal title="编辑短信模板" :width="500" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    保存
                </a-button>
            </template>
            <div>
                <a-spin :spinning="loading">
                    <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18}">
                        <a-form-model-item label="模板名称" required>
                            <a-input style="width: 300px;" placeholder="请输入模板名称" v-model="form.template_name" />
                        </a-form-model-item>

                        <a-form-model-item label="模板ID" required>
                            <a-input style="width: 300px;" placeholder="请输入模板ID" v-model="form.template_id" />
                        </a-form-model-item>

                        <a-form-model-item label="模板内容" required>
                            <a-textarea style="width: 300px;" placeholder="请输入模板内容" :rows="3" v-model="form.template" />
                        </a-form-model-item>

                        <a-form-model-item label="发送的英文KEY" required>
                            <a-input style="width: 300px;" placeholder="请输入发送的英文KEY" :change="check()" v-model="form.KEY" />
                        </a-form-model-item>

                        <a-form-model-item label="作用" required>
                            <a-select v-model="form.role" style="width: 300px;">
                                <a-select-option :value="item.id" v-for="(item,index) in roleList" :key="index">
                                    {{item.title}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>

                    </a-form-model>
                </a-spin>
            </div>
        </a-modal>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        tpl_id: {
            type: Number,
            default: 0
        },
        tpl_info: {
            type: Object,
            default: {}
        },
    },
    created() {
        if (this.tpl_id != 0) {
            this.loaddata();
        }

    },
    data() {
        return {
            loading: false,
            confirmLoading: false,
            form: {
                template_id: '',
                template: '',
                KEY: '',
                role: '',
                template_name: '',
            },
            roleList: [
                { title: '余额消耗通知', id: 1 },
                { title: '订单发货通知', id: 2 },
                { title: '订单核销通知', id: 3 },
                { title: '用户预约通知', id: 4 },
                { title: '预约取消通知', id: 5 },
                { title: '积分扣减通知,', id: 6 },
                { title: '新增预约通知管理员', id: 7 },
                { title: '服务人员预约通知', id: 8 },
                { title: '订单通知管理员', id: 9 },
                { title: '会员消费短信', id: 10 },
                { title: '会员充值短信', id: 11 },
                { title: '会员办卡短信', id: 12 },
                { title: '会员预约短信', id: 13 },
            ],
        }
    },
    methods: {
        loaddata() {
            this.form = {
                template_id: this.tpl_info.template_id,
                template: this.tpl_info.template_content,
                KEY: this.tpl_info.api_key,
                role: this.tpl_info.type,
                template_name: this.tpl_info.template_name,
            }
        },
        handleCancel() {
            this.$emit('cancel');
        },
        onSubmit() {
            if (this.confirmLoading == true) return;
            let datas = {
                template_id: this.form.template_id,
                template_content: this.form.template,
                api_key: this.form.KEY,
                type: this.form.role,
                template_name: this.form.template_name,
            }
            let url = 'api/sms/SmsTemplateAdd'
            if (this.tpl_id != 0) {
                url = 'api/sms/SmsTemplateEdit'
                datas.id = this.tpl_id
            }
            this.confirmLoading = true;
            this.$http.api(url, datas).then(res => {
                this.$message.success('保存成功', 1, () => {
                    this.confirmLoading = false;
                    this.$emit('ok');
                })
            }).catch(res => {
                this.confirmLoading = false;
            })
        },
        check() {
            this.form.KEY = this.form.KEY.replace(/[^a-zA-Z]/g, '');
        },
    }
}
</script>

<style>
</style>